import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53d4e2ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-between align-items-center playerListItem" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "w-100 d-flex justify-space-between align-center ml-5" }
const _hoisted_4 = { class: "d-flex flex-column align-start" }
const _hoisted_5 = {
  key: 0,
  class: "player-status"
}
const _hoisted_6 = { class: "d-flex flex-column" }
const _hoisted_7 = { class: "d-flex flex-row align-center" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("img", {
        src: _ctx.player.imageLink,
        alt: ""
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", null, [
          _createTextVNode(_toDisplayString(_ctx.player.name) + " ", 1),
          (_ctx.player.status != 'None')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.player.status), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, _toDisplayString(_ctx.player.projected), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createTextVNode(_toDisplayString(_ctx.player.position) + " ", 1),
          _createElementVNode("img", {
            src: _ctx.imgLink(_ctx.player),
            alt: "",
            class: "team-img"
          }, null, 8, _hoisted_8)
        ]),
        _createElementVNode("div", null, " $" + _toDisplayString(_ctx.player.salary), 1)
      ])
    ])
  ]))
}