<template>
    <v-card variant="tonal" class="lineupCard">
      <v-card-text>
        <div class="d-flex justify-space-between align-space-between flex-column">
          <div class="d-flex justify-space-between" v-for="(player, index) in lineup.players" :key="index">
            <div> {{ playerInfo(player) }} </div>
            <div> ${{ player.salary}} </div>
          </div>
          <div class="mt-5 d-flex justify-space-between align-space-between">
            <p>Projected: {{lineup.projected.toFixed(2)}}</p>
            <p>Total Salary: ${{lineup.totalSalary}}</p>
          </div>
        </div>
      </v-card-text>
    </v-card>
</template>

<script lang="ts">

import { Lineup } from '@/models/Lineup';
import { PropType } from 'vue';
import { defineComponent } from 'vue'
import { Player } from '../models/Player'

export default defineComponent({
  name: 'LineupComponent',
  props: {
    lineup: Object as PropType<Lineup>
  },
  data() {
    return {
      isLoading: false,
      playerInfo(player: Player): string {
        return `${player.name} - ${player.position} - ${player.projected}`
      }
    };
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lineupCard {
  width: 360px; 
}
</style>
