import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b913bb50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-space-between align-space-between flex-column" }
const _hoisted_2 = { class: "mt-5 d-flex justify-space-between align-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    variant: "tonal",
    class: "lineupCard"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lineup.players, (player, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "d-flex justify-space-between",
                key: index
              }, [
                _createElementVNode("div", null, _toDisplayString(_ctx.playerInfo(player)), 1),
                _createElementVNode("div", null, " $" + _toDisplayString(player.salary), 1)
              ]))
            }), 128)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, "Projected: " + _toDisplayString(_ctx.lineup.projected.toFixed(2)), 1),
              _createElementVNode("p", null, "Total Salary: $" + _toDisplayString(_ctx.lineup.totalSalary), 1)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}