<template>
  <v-container fluid>
    <h1 class="ml-2 mb-5 d-flex align-start">Games</h1>
    <v-progress-linear v-show="isLoading" indeterminate color="green" ></v-progress-linear>
    <div v-for="[key, value] in groupedGames(games)" :key="key" class="d-flex flex-column align-start">
      <h2 class="ml-2">{{key}}</h2>
      <v-expansion-panels class="d-flex justify-start flex-wrap">
          <v-expansion-panel v-for="(game, index) in value" :key="index" class="bg-grey-darken-3 my-2">
            <v-expansion-panel-title  hide-actions>
              <div class="w-100 d-flex align-center justify-space-between flex-row">
                <div class="d-flex flex-row align-center justify-space-around" style="width:220px">
                  <p>{{homeTeamAbbv(game)}}</p>
                  <img :src="homeTeamImg(game)" alt="" class="team-img">
                  <p class="align-self-center">vs</p>
                  <p>{{awayTeamAbbv(game)}}</p>
                  <img :src="awayTeamImg(game)" alt="" class="team-img">
                </div>
                <div>
                  {{(new Date(game.startTime)).toLocaleTimeString()}}
                </div>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-for="(contest, index) in game.contests" :key="index" @click="goToContest(contest.draftGroupId)" class="d-flex mt-2 justify-space-between">
                <span class="contest-title-container contest-title">
                  {{contest.name}}
                </span>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { ObjectFactory } from '../api/ObjectFactory'
import { IDraftKingsApi } from '../api/IDraftKingsApi'
import { Game } from '@/models/Game';

export default defineComponent({
  name: 'ContestsPage',
  components: { },
  data() {
    return {
      isLoading: true,
      api: ObjectFactory.CreateDraftKingsApi() as IDraftKingsApi,
      games: [] as Array<Game>,
    };
  },
  methods: {
      goToContest: function(draftGroupId: number) {
        this.$router.push('/contests/' + draftGroupId);
      },
      homeTeamImg(game: Game): string {
        let abbv = this.homeTeamAbbv(game).trim();
        return `https://sportsbook.draftkings.com/static/logos/teams/nfl/${abbv}.png`;
      },
      awayTeamImg(game: Game): string {
        let abbv = this.awayTeamAbbv(game).trim();
        return `https://sportsbook.draftkings.com/static/logos/teams/nfl/${abbv}.png`;
      },
      homeTeamAbbv(game: Game): string {
        return game.name.split("@")[0]
      },
      awayTeamAbbv(game: Game): string {
        return game.name.split("@")[1]
      },
      groupedGames(games: Game[]): Map<string, Game[]> {
        const groupedGames = new Map<string, Game[]>();

        games.forEach((game) => {
          const dateKey = new Date(game.startTime).toDateString()

          if (groupedGames.has(dateKey)) {
            groupedGames.get(dateKey)?.push(game);
          } else {
            groupedGames.set(dateKey, [game]);
          }
        });

        return groupedGames;
      }
  },
  async mounted() {
    this.isLoading = true;

    this.games = await this.api.GetGamesAsync();

    this.isLoading = false
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.card {
  width: 250px
}

.contest-title:hover {
  cursor: pointer;
  color: lightgreen 
}

.contest-title-container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.team-img {
  height: 50px
}
</style>
