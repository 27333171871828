<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="4">

        <v-container>
          <v-row no-gutters class="d-flex justify-content-start mb-3">
            <h1>Available Players</h1>
          </v-row>

          <v-row v-if="isLoadingPlayers">
            <v-progress-linear 
              indeterminate 
              color="green"/>
          </v-row>

          <v-row class="mt-6">
            <v-virtual-scroll
              :height="isMobile ? 350 : 1000"
              :items="players">
              <template v-slot:default="{item}">
              <playerListItem 
                class="mb-3"
                :player="item"
                @click="() => selectPlayer(item)"/>
              </template>
            </v-virtual-scroll>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="8">

        <v-container>
          <v-row no-gutters class="d-flex"  align-content="center">
            <v-col justify-content="start" class="d-flex">
              <h1 class="">Lineups</h1>
            </v-col>
            <v-col
             v-if="!isLoadingLineups"
             justify-content="end" 
             class="display-options-style d-flex">
              <v-select style="max-width: 280px"
                label="Display"
                v-model="displayItems"
                density="compact"
                :items="['Top 10 lineups', 'Top 25 lineups', 'Top 50 lineups', 'All lineups']"
                variant="outlined"/>
            </v-col>
          </v-row>

          <v-row v-if="isLoadingLineups">
            <v-progress-linear 
              indeterminate 
              color="green"/>
          </v-row>

          <v-row v-if="selectedPlayers.length > 0" class="d-flex flex-wrap mb-5">
              <playerListItem 
                :style="isMobile? 'width: 100%' :  'width: 280px'"
                v-for="(player, index) in selectedPlayers" 
                :key="index" 
                :player="player"
                :class="isMobile? 'mb-3' :  'mb-3 mr-3'"
                @click="() => removePlayer(player)"/>
          </v-row>


          <v-row class="d-flex align-content-start flex-wrap">
            <div 
              v-for="(lineup, index) in filteredLineups" 
              :key="index" 
              variant="outlined" 
              class="card mx-2 mb-10">
                <lineup-component 
                  :lineup="lineup">
                </lineup-component >
            </div>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import LineupComponent from './LineupComponent.vue'
import PlayerListItem from './player/PlayerListItem.vue'
import { ObjectFactory } from '../api/ObjectFactory'
import { IDraftKingsApi } from '../api/IDraftKingsApi'
import { Player } from '../models/Player'
import { Lineup } from '@/models/Lineup'
import { Contest } from '@/models/Contest'

export default defineComponent({
  name: 'ContestsPage',
  components: {
    LineupComponent,
    PlayerListItem 
  },
  props: {
    id: {
      type: Number,
      required: true,
    } 
  },
  data() {
    return {
      isLoadingPlayers: true,
      isLoadingLineups: true,
      players: [] as Array<Player>,
      selectedPlayers: [] as Array<Player>,
      filteredLineups: [] as Array<Lineup>,
      lineups: [] as Array<Lineup>,
      contests: [] as Array<Contest>,
      displayItems: 'Top 10 lineups',
      api: ObjectFactory.CreateDraftKingsApi() as IDraftKingsApi,
    };
  },
  methods: {
    selectPlayer(playerToAdd: Player) {
      var playerIndex = this.players.findIndex(player => player.id === playerToAdd.id);

      this.selectedPlayers.push(playerToAdd);
      this.players.splice(playerIndex, 1)

      this.filterLineups();
    },
    removePlayer(playerToRemove: Player) {
      var playerIndex = this.selectedPlayers.findIndex(player => player.id === playerToRemove.id);

      this.players.push(playerToRemove);
      this.selectedPlayers.splice(playerIndex, 1)

      this.players = this.players.sort((player1, player2) => player2.salary - player1.salary);

      this.filterLineups();

    },
    filterLineups() {
      this.filteredLineups = this.lineups.filter(lineup => {
        return this.selectedPlayers.every(selctedPlayer => lineup.players.some(lineupPlayer => lineupPlayer.id === selctedPlayer.id));
      });
      this.filteredLineups = this.filteredLineups.sort((l1, l2) => l2.projected - l1.projected);

      switch (this.displayItems) {
        case 'Top 10 lineups':
          this.filteredLineups = this.filteredLineups.slice(0,10)
          break;
        case'Top 25 lineups':
          this.filteredLineups = this.filteredLineups.slice(0,25)
          break;
        case 'Top 50 lineups':
          this.filteredLineups = this.filteredLineups.slice(0,50)
          break;
        case 'All lineups':
          break;
      }
    }
  },
  watch: {
    displayItems() {
      this.filterLineups();
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown ; // Returns true if viewport is under medium size (sm, xs)
    },
  },
  async mounted() {
    this.isLoadingPlayers = true;
    this.isLoadingLineups = true;

    this.players = await this.api.GetDraftablesAsync(this.id);
    this.players = this.players.sort((player1, player2) => player2.salary - player1.salary);
    this.isLoadingPlayers = false;

    this.lineups = await this.api.GetLineupsAsync(this.id);
    this.filteredLineups = this.lineups;
    this.filterLineups();
    this.isLoadingLineups = false;
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.card {
  width: 360px
}

.display-options-style {
  justify-content: end
}
</style>
