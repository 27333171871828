import { DraftKingsApi } from "./DraftKingsApi";
import { IDraftKingsApi } from "./IDraftKingsApi"
import { MockDraftKingsApi } from "./MockDraftKingsApi"

export class ObjectFactory {

    static useMocks: boolean = false;
    static debug: boolean = false;

    static draftKingsApi: IDraftKingsApi = new DraftKingsApi(this.debug === true ? "http://localhost:5135" : "https://api.dkedge.net");
    static mockDraftKingsApi: IDraftKingsApi = new MockDraftKingsApi();

    public static CreateDraftKingsApi(): IDraftKingsApi {
        // return ObjectFactory.useMocks ?  new MockDraftKingsApi() : new DraftKingsApi("http://localhost:5135");
        return ObjectFactory.useMocks ?  this.mockDraftKingsApi : this.draftKingsApi;
    }

}
