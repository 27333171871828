import { Player } from '../models/Player'
import { Game } from '../models/Game'
import { Position } from '../models/Position';
import { IDraftKingsApi } from './IDraftKingsApi';
import { Lineup } from '@/models/Lineup';

export class MockDraftKingsApi implements IDraftKingsApi {

    constructor() { }

    GetLineupsAsync(contestId: number): Promise<Lineup[]> {

        const lineup = {
            players: [  
            { name: 'player1', salary: 1230, position: Position.QB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png" } as Player,
            { name: 'player2', salary: 5402, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
            { name: 'player3', salary: 3248, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png" } as Player,
            { name: 'player4', salary: 2394, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
            { name: 'player5', salary: 3282, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
            { name: 'player6', salary: 2341, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
        ] as Player[] } as Lineup;

        const mockLineups: Lineup[] = [];
        const numberOfCopies = 100;

        for (let i = 0; i < numberOfCopies; i++) {
            const copy = { ...lineup};
            mockLineups.push(copy);
        }

        return new Promise((resolve) => {
            resolve(mockLineups);
        });
    }

    GetDraftablesAsync(contestId: number): Promise<Player[]> {

        return new Promise((resolve) => {
            resolve([
                { name: 'player1', salary: 1230, position: Position.QB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png" } as Player,
                { name: 'player2', salary: 5402, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
                { name: 'player3', salary: 3248, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png" } as Player,
                { name: 'player4', salary: 2394, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
                { name: 'player5', salary: 3282, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
                { name: 'player6', salary: 2341, position: Position.RB, imageLink: "https://dkn.gs/sports/images/nfl/players/50/485450.png"} as Player,
            ]);
        });

    }

    async GetGamesAsync(): Promise<Game[]> {

        return new Promise((resolve) => {
            resolve([
                { name: 'DAL vs PHI', id: 123, startTime : new Date(), sport : "NLF", contests: [
                    { name: 'Showdown 1', id: 123, prize: 1000, draftGroupId: 1 }
                ] },
                { name: 'DAL vs PHI', id: 123, startTime : new Date(), sport : "NLF", contests: [
                    { name: 'Showdown 1', id: 234, prize: 1000, draftGroupId: 1 }
                ] },
                { name: 'DAL vs PHI', id: 123, startTime : new Date(), sport : "NLF", contests: [
                    { name: 'Showdown 1', id: 345, prize: 1000, draftGroupId: 1 }
                ] },
                { name: 'DAL vs PHI', id: 123, startTime : new Date(), sport : "NLF", contests: [
                    { name: 'Showdown 1', id: 456, prize: 1000, draftGroupId: 1 }
                ] },
            ]);
        });
    }
}