import { Player } from '../models/Player'
import { Game } from '../models/Game'
import { IDraftKingsApi } from './IDraftKingsApi';
import { Lineup } from '@/models/Lineup';

import axios, { AxiosResponse } from 'axios';

export class DraftKingsApi implements IDraftKingsApi {
    private baseUrl: string;

    private cachedGames: Game[];
    private cachedDraftables: Map<number, Player[]>;
    private cachedLineups: Map<number, Lineup[]>;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;

        this.cachedGames= [] as Game[];
        this.cachedDraftables= new Map<number, Player[]>;
        this.cachedLineups= new Map<number, Lineup[]>;
    }

    private async makeRequest<T>(endpoint: string): Promise<T> {
        const url = `${this.baseUrl}${endpoint}`;
        const response: AxiosResponse<T> = await axios.get(url);
        return response.data;
    }

    async GetGamesAsync(): Promise<Game[]> {

        if (this.cachedGames.length !== 0) {
            return Promise.resolve(this.cachedGames);
        }
        else {
            const endpoint = '/api/DraftKings/games';
            const games = await this.makeRequest<Game[]>(endpoint);

            this.cachedGames = games;

            return games;
        }
    }

    async GetDraftablesAsync(contestId: number): Promise<Player[]> {

        if (this.cachedDraftables.has(contestId)) {
            return Promise.resolve(this.cachedDraftables.get(contestId) as Player[]);
        }
        else {
            const endpoint = `/api/DraftKings/draftables?contestId=${contestId}`;
            const players  = await this.makeRequest<Player[]>(endpoint);

            this.cachedDraftables.set(contestId, players);

            return players;
        }
    }

    async GetLineupsAsync(contestId: number): Promise<Lineup[]> {

        if (this.cachedLineups.has(contestId)) {
            return Promise.resolve(this.cachedLineups.get(contestId) as Lineup[]);
        }
        else {
            const endpoint = `/api/DraftKings/lineups?contestId=${contestId}`;
            const lineups = await this.makeRequest<Lineup[]>(endpoint);

            this.cachedLineups.set(contestId, lineups);

            return lineups;
        }
    }
}