<template>
  <v-app id="inspire">
    <v-app-bar flat class="bg-grey-darken-2">
      <div class="d-flex align-center justify-center">
            <router-link to="/games" class="link">
              <v-btn text="Games" variant="text" ></v-btn>
            </router-link>
            <router-link to="/lineups" class="link">
              <v-btn text="Saved Lineups" variant="text" ></v-btn>
            </router-link>
      </div>
    </v-app-bar>

    <v-main class="bg-grey-darken-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
    }),
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.link {
  color: lightgrey;
}

.menu-items {
  padding: 16px;
}

.app-bar-title {
  max-width: 100px;
  min-width: 100px;
}
</style>
