import { createRouter, createWebHashHistory } from "vue-router";

import GamesPage from '@/components/GamesPage.vue';
import ContestPage from '@/components/ContestPage.vue';
import PlayersPage from '@/components/PlayersPage.vue';

const routes = [
  { path: '/', redirect: () => {
    // the function receives the target route as the argument
    // we return a redirect path/location here.
    return { path: '/games' }
  } },
  { path: '/games', component: GamesPage },
  { path: '/contests/:id', component: ContestPage, props: true},
  { path: '/lineups', component: PlayersPage },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;