<template>
  <div class="d-flex justify-content-between align-items-center playerListItem">
    <div>
      <img :src="player.imageLink" alt="">
    </div>
    <div class="w-100 d-flex justify-space-between align-center ml-5">
        <div class="d-flex flex-column align-start">
        <div>
        {{player.name}} <span v-if="player.status != 'None'" class="player-status">{{ player.status }}</span>
        </div>
        <div>
        {{player.projected}}
        </div>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-center">
          {{player.position}}
          <img :src="imgLink(player)" alt="" class="team-img">
          </div>
          <div>
          ${{player.salary}}
          </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { Player } from '../../models/Player'
import type { PropType } from 'vue';

export default defineComponent({
  name: 'PlayerListItem',
  components: { },
  props: {
    player: Object as PropType<Player>
  },
  data() {
    return {
      isLoading: false,
      
    };
  },
  methods: {
    imgLink(player: Player): string {
      return `https://sportsbook.draftkings.com/static/logos/teams/nfl/${player.teamAbbreviation}.png`;
    },
  },
  async mounted() {

  }
})
</script>

<style scoped>
.playerListItem {
  border: solid 1pt;
  border-radius: 10px;
  border-color: darkgrey;
  padding: 10px
}
.playerListItem:hover {
  background-color: grey;
  cursor: pointer
}

.player-status {
  font-weight: bold;
  color: red;
}

.team-img {
  width: 25px;
  position: relative;
  top: -1pt;
  right: -1pt;
}
</style>
